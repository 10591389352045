import React from "react";
import { NavLink, useNavigate } from 'react-router-dom'
import "./../App.css";
import robot from './../images/android-chrome-192x192.png';

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-WQXKE0M3J5";

function Privacy() {

  React.useEffect(() => {
      ReactGA.initialize(TRACKING_ID);
      // Send pageview with a custom path
      ReactGA.send({ hitType: "pageview", page: "/privacy", title: "Privacy Page" });
  }, [])

  return (
    <div className="App">
      <header className="App-header">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <center>
                <img src={robot} alt="robot"/>
                <h1 className="brand">ROBOTICS.DEV</h1>
                <p>Robotics Developer Platform</p>
                <p>
                  Privacy Policy
                </p>
              </center>
            </div>
          </div>
        </div>
      </header>
    </div>
  )
}

export default Privacy;
