import React, { useEffect, useState }  from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import { CopyBlock, CodeBlock, dracula } from "react-code-blocks";
import "./../App.css";
import robot from './../images/android-chrome-192x192.png';
import { auth, db } from '../firebase';
import { onAuthStateChanged, signOut } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-WQXKE0M3J5";

function Home() {
  const [join, setJoin] = useState(true);
  const [email, setEmail] = useState("");

  useEffect(() => {
      ReactGA.initialize(TRACKING_ID);
      // Send pageview with a custom path
      ReactGA.send({ hitType: "pageview", page: "/home", title: "Home Page" });

      onAuthStateChanged(auth, (user) => {
          if (user) {
            setEmail(user.email);
          }
        });
  }, [])

  const joinToggle = (e) => {
    setJoin(false);
  }

  const onJoin = (e) => {
      e.preventDefault();
      if(email !== ""){
        setDoc(doc(db, "waitlist", email), {
          allow: false
        }, { merge: true })
        .then(
          setJoin(true)
        )
      } else {
        setJoin(true)
      }
  }

  return (
    <div className="App body-home">
      <header className="header-home">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <center>
                <img src={robot} alt="robot"/>
                <span className="logo-tag">.dev</span>
              </center>
            </div>
            <div className="col-sm-6">
              <center>
                <h2 className="brand2">ROBOTICS.DEV</h2>
                <p>Robotics Developer Platform</p>
                <div className="cta">
                  {join ?
                    <center>
                    {email ? `${email} joined!` :
                    <button onClick={joinToggle} className="btn btn-primary btn-lg">
                      Join Waitlist
                    </button>
                    }
                    </center>
                  :
                    <>
                      <input
                          id="email-address"
                          name="email"
                          type="email"
                          required
                          className="form-control form-control-lg"
                          placeholder="Email address"
                          onChange={(e)=>setEmail(e.target.value)}
                      />
                      <button onClick={onJoin} className="btn btn-outline-light btn-lg" >
                          Join
                      </button>
                    </>
                  }
                </div>
              </center>
            </div>
          </div>
        </div>
      </header>

      <section className="platform-summary">
          <div className="section-content">
              <p>Robotics.dev is a revolutionary Robotics-as-a-Service (RaaS) developer platform that decouples AI computation from robots, enabling unprecedented scalability and innovation. By abstracting the complexities of ROS and low-level robotics code, Robotics.dev allows developers and teams to focus on creating powerful AI robotics apps as well as LLM integrations, orchestrating multi-robot fleets, harvesting telemetry data, and performing advanced robotics tasks—all from the cloud, edge devices, and peer-to-peer developer machines (Linux, Windows, and Macs with/without ROS).</p>
          </div>
      </section>

      <section className="features-section">
          <div className="section-content">
              <div className="features">
                  <div className="feature">
                      <i className="fas fa-globe"></i>
                      <h3>Global Connectivity</h3>
                      <p>Extend ROS2 capabilities beyond local networks for worldwide AMR robot control (SDK, APIs, and Web Teleop) and real-time AI analysis. </p>
                  </div>
                  <div className="feature">
                      <i className="fas fa-cloud"></i>
                      <h3>Decoupled Compute</h3>
                      <p>Run AI on the cloud or edge or peer-to-peer development environment, reducing robot hardware costs, SSHing, and conserving battery life.</p>
                  </div>
                  <div className="feature">
                      <i className="fas fa-robot"></i>
                      <i className="fas fa-robot"></i>
                      <h3>Multi-Robot Orchestration</h3>
                      <p>Control fleets of robots or orchestrate multiple AI tasks on the same robot with ease.</p>
                  </div>
                  <div className="feature">
                      <i className="fas fa-network-wired"></i>
                      <h3>Peer-to-Peer Communication</h3>
                      <p>Supports direct communication between robots and developer machines (or Docker containers) anywhere in the world.</p>
                  </div>
                  <div className="feature">
                      <i className="fas fa-plug"></i>
                      <h3>Easy Integration</h3>
                      <p>Runs with existing ROS apps. Compatible with 2D and 3D depth cameras and a wide range of hardware platforms. We also offer a simple REST API and WebSocket support for developers!</p>
                  </div>
                  <div className="feature">
                      <i className="fas fa-code"></i>
                      <h3>Developer Tools</h3>
                      <p>Choose between our no-code designer, VS Code Extension + GitHub Copilot, or utilize our powerful SDKs for NodeJS and Python development.</p>
                  </div>
              </div>
          </div>
      </section>

      <section className="hardware-support">
          <div className="section-content">
            <div className="setup-steps">
              <center><img className="d-block" src="ros.png" alt="ROS" width="300px"/></center><br/>
              <h3><center>Robotics.dev works with any ROS2 AMR robot!
              <br />If you need a ROS2 Motor Controller, we natively support the following hardware:</center></h3>
            </div>
              <div className="boards">
                  <div className="board">
                      <i className="fab fa-raspberry-pi"></i>
                      <h3>Raspberry Pi</h3>
                      <p>RPi3, RPi4, RPi5, RPi Zero</p>
                  </div>
                  <div className="board">
                      <i className="fas fa-microchip"></i>
                      <h3>Radxa X4 (x86)</h3>
                  </div>
                  <div className="board">
                      <i className="fas fa-microchip"></i>
                      <h3>LattePanda 3 Delta (x86)</h3>
                  </div>
                  <div className="board">
                      <i className="fas fa-desktop"></i>
                      <i className="fas fa-microchip"></i>
                      <h3>Intel NUC</h3>
                      <p>with Arduino/ESP32</p>
                  </div>
              </div>
          </div>
      </section>

      <section className="hardware-support">
          <div className="section-content">
            <div className="setup-steps">
              <h3><center>Sneakpeak</center></h3>
            </div>

              <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img className="d-block w-100" src="robots.png" alt="Fleet management"/>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src="teleop.png" alt="Web teleop"/>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src="designer.png" alt="No-code designer"/>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src="vscode.png" alt="VS Code Co-Pilot"/>
                  </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="sr-only">Next</span>
                </a>
              </div>
          </div>
      </section>

      <section className="code-sample">
          <h2><center>How Does It Work?</center></h2>
          <div className="code-columns">
              <div className="setup-steps">
                  <h3><i className="fas fa-robot"></i><br/>Getting Started with Robot</h3>
                  <p>Install Robotics.dev CLI (command line interface) on robot. Learn more about our <a href="https://www.npmjs.com/package/robotics" target="_new">Robotics NPM package</a>.</p>
                  <div className="command">npm install -g robotics</div>

                  <p>Get Robot ID and add it to your list of robots in Robotics.dev</p>
                  <div className="command">robotics id</div>

                  <p>Start Communications (defaults to robotics.dev)</p>
                  <div className="command">robotics connect</div>

                  <p>Start Motors (defaults to Raspberry Pi)</p>
                  <div className="command">robotics start motors</div>

                  <p>Start 2D Camera (defaults to device /dev/video0)</p>
                  <div className="command">robotics start camera</div>
              </div>
              <div className="setup-steps">
                  <h3><i className="fas fa-code"></i><br/>Getting Started with SDK</h3>
                  <p>Install Robotics.dev SDK on development machine (Linux, Windows, Mac). Learn more about our <a href="https://www.npmjs.com/package/robotics-dev" target="_new">Robotics-dev NPM package</a>.</p>
                  <div className="command">npm install robotics-dev</div>
                  OR
                  <div className="command">pip install robotics-dev</div>

                  <div className="code-container">
                  <CopyBlock
                    text={`//import robotics from 'robotics-dev';
const robotics = require('robotics-dev');

// Define ROS twist movement commands
const moveForward = {
    linear: {x: 0.2, y: 0.0, z: 0.0},
    angular: {x: 0.0, y: 0.0, z: 0.0}
};
const turnLeft = {
    linear: {x: 0.0, y: 0.0, z: 0.0},
    angular: {x: 0.0, y: 0.0, z: 0.2}
};
const turnRight = {
    linear: {x: 0.0, y: 0.0, z: 0.0},
    angular: {x: 0.0, y: 0.0, z: -0.2}
};
const stop = {
    linear: {x: 0.0, y: 0.0, z: 0.0},
    angular: {x: 0.0, y: 0.0, z: 0.0}
};

var latestImage;
var oneTime = true;
const robotId = 'ENTER ROBOTICS.DEV ROBOT ID HERE';

// Connect SDK to robot via P2P
robotics.connect({robot: robotId}, (ros) => {
  // listen for ROS messages
  console.log('Received p2p data:', ros);
  // {
  //   robotId: '3bbb40c5-xxxx-yyyy-zzzz-58a12bc6893e',
  //   topic: '/camera2d',
  //   data: {
  //     data: xyz
  //   }
  // }
  if(ros.topic === "/camera2d"){
    console.log("Base64 image: ", ros.data.data);
    latestImage = ros.data.data
  }

  if(oneTime){
    oneTime = false;
    robotics.speak(robotId, 'this is a test')

    console.log('Moving robot forward at 20% speed...');
    robotics.twist(robotId, forwardTwist);

    // Stop after 5 seconds
    setTimeout(() => {
        console.log('Stopping robot...');
        robotics.twist(robotId, stopTwist);
    }, 5000);
  }

});
                      `}
                    language={"javascript"}
                    showLineNumbers={false}
                    wrapLongLines
                    theme={dracula}
                  />


                  </div>
              </div>
          </div>
      </section>

      <footer>
          <p>&copy; 2025. All rights reserved.</p>
      </footer>

    </div>
  )
}

export default Home;
