import * as process from 'process'; // needed for p2p

import React, { useEffect, useState }  from "react";
import { NavLink, useNavigate } from 'react-router-dom'

import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from '../firebase';
import { addDoc, getDoc, getDocs, doc, setDoc, deleteDoc, updateDoc, deleteField, query, collection, where } from "firebase/firestore";

import { Joystick } from 'react-joystick-component-godofkim';
import io from 'socket.io-client';
import "./../App.css";

import Peer from 'simple-peer';
import pako from 'pako';

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-WQXKE0M3J5";
const { REACT_APP_NODE_ENV, REACT_APP_IP_ADDRESS } = process.env;

var socket;
var peer;
window.global = window;
window.process = process;
window.Buffer = [];

let receivedChunks = [];
let totalChunks = null;

const messages = new Map();

const Control = () => {

  const navigate = useNavigate();

  const [speed, setSpeed] = useState(20);
  const [lastStick, setLastStick] = useState(null);
  const [lastGamepad, setLastGamepad] = useState('STOP');
  const [uid, setUid] = useState('');
  const [robot, setRobot] = useState('');
  const [robots, setRobots] = useState([]);
  const [rgbStream, setRgbStream] = useState('');
  const [presence, setPresence] = useState(false);
  const [p2p, setP2p] = useState(false);

  async function getRobots(userid){
    const q = query(collection(db, "robots"), where("uid", "==", userid));
    const querySnapshot = await getDocs(q);
    if(querySnapshot.size > 0){
      let robotList = [];
      querySnapshot.forEach((doc) => {
        // console.log(doc.data());
        var robotX = doc.data();
        robotX.id = doc.id;
        robotList.push(robotX);

        // // Moving from cloud streams to P2P
        // socket.on(`${robotX.id}/camera2d`, (message) => {
        //   // console.log(message);
        //   if(document.getElementById("mrRobot") && message.id === document.getElementById("mrRobot").value){
        //     setRgbStream("data:image/png;base64, " + message.data.data);
        //   } else {
        //     setRgbStream("data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=")
        //   }
        // });

      });
      setRobots(robotList);
      setRobot(robotList[0].id)
    } else {
      setRobots([]);
    }
  }

  useEffect(() => {
      ReactGA.initialize(TRACKING_ID);
      ReactGA.send({ hitType: "pageview", page: "/control", title: "Control Page" });

      onAuthStateChanged(auth, (user) => {
        if (user) {
          setUid(user.uid);
          getRobots(user.uid);
        } else {
          console.log("user is logged out")
          navigate("/")
        }
      });

      // connect to socket.io
      // var socket
      // console.log(REACT_APP_NODE_ENV, REACT_APP_IP_ADDRESS);
      if (REACT_APP_NODE_ENV === 'development') {
        if(REACT_APP_IP_ADDRESS){
          socket = io(`http://${REACT_APP_IP_ADDRESS}:3001`);
        } else {
          socket = io('http://localhost:3001');
        }
        // console.log('development');
      }
      if (process.env.NODE_ENV === 'production') {
        socket = io('https://robotics.dev');
        // console.log('production');
      }

      socket.on("connect", () => {
        // console.log("socketId:", socket.id); // x8WIv7-mJelg7on_ALbx
      });

  }, [])

  useEffect(() => {

    if(robot){
      // update presence
      fetch(`/presence/${robot}`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json'
        },
      })
      .then((res) => res.json())
      .then((output) => {
        // console.log(output.online);
        setPresence(output.online)

        if(output.online){
          // connect p2p
          try{
            peer = new Peer({
              initiator: true,
              trickle: false,
              config: {
                iceServers: [
                  { urls: 'stun:stun.l.google.com:19302' }, // STUN server
                ],
              },
            });

            if (peer && !peer.destroyed) {
                peer.on('signal', (data) => {
                  // console.log("peer data", data);
                  if(data.type === "offer"){
                    socket.emit('peer', {robot: robot, data});
                  }
                });


              peer.on('error', (err) => {
                console.error('Peer connection error:', err);
              });

              peer.on('close', () => {
                console.log('Peer connection closed');
                // peer.destroy()
              });

              peer.on('connectionStateChange', () => {
                const state = peer._pc.connectionState;
                console.log('Connection state:', state);

                if (state === 'failed' || state === 'disconnected') {
                  console.error('Connection failed. Retrying...');
                  // Retry logic or alert the user
                }
              });

              peer.on('icecandidate', (candidate) => {
                console.log('New ICE candidate:', candidate);
              });

              peer.on('connect', () => {
                // wait for 'connect' event before using the data channel
                console.log("robot connected p2p!");
                // peer.send('hey robot, how is it going?');
                setP2p(true);
              });

              // got a data channel message
              peer.on('data', (data) => {

                let str=new TextDecoder("utf-8").decode(data)
                const { chunk, index, total, messageId } = JSON.parse(str);
                // console.log(messageId, index, total);
                // var messageChunk = pako.inflate(chunk, { to: 'string' }); //decompress
                // var messageChunk = pako.inflate(chunk); //decompress
                var messageChunk = chunk;
                // console.log(messageChunk);

                if (!messages.has(messageId)) {
                    // Initialize a new entry for the message ID
                    messages.set(messageId, {
                        receivedChunks: [],
                        totalChunks: total,
                    });
                }

                const currentMessage = messages.get(messageId);

                // Store the chunk at the correct index
                currentMessage.receivedChunks[index] = messageChunk;

                // Check if all chunks have been received
                if (currentMessage.receivedChunks.filter(Boolean).length === currentMessage.totalChunks) {
                    // Reassemble the full message
                    const fullData = currentMessage.receivedChunks.join('');
                    const originalData = JSON.parse(fullData);

                    // console.log(`Received full data for messageId ${messageId}:`, originalData);

                    // Cleanup completed message
                    messages.delete(messageId);
                    // console.log(originalData);

                    // 2D camera stream
                    if(originalData.topic === "/camera2d"){
                      if(document.getElementById("mrRobot") && originalData.robotId === document.getElementById("mrRobot").value){
                        // setRgbStream(`data:image/png;base64, ${Buffer.from(message.data.data, "base64").toString()}`);
                        setRgbStream(`data:image/png;base64, ${originalData.data.data}`);
                      } else {
                        setRgbStream("data:image/gif;base64, R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=")
                      }
                    }
                }


              });
            }

            socket.on('peerreply', (data) => {
              // console.log('received handshake from robot', data);
              if (peer && !peer.destroyed) {
                peer.signal(data);
              }
            });

          } catch(error){
            console.log("rtc error", error);
          }
        }

      });
    }



    // Gamepad loop
    var gamepadDirection = "STOP";
    var lastGame = null
    var twistMsg;
    var speeder;
    const interval = setInterval(() => {
      const controller = navigator.getGamepads()[0];
      if(controller){
        // console.log(gamepadDirection, lastGame);

        const x = Math.trunc(controller.axes[0]*100)/100
        const y = Math.trunc(controller.axes[1]*100)/100
        speeder = document.getElementById("speeder").value;

        if(x > 0.2){
          twistMsg = {linear: {x: 0.0, y: 0.0, z: 0.0}, angular: {x: 0.0, y: 0.0, z: speeder/100}}
          gamepadDirection = 'RIGHT'
        } else if(x < -0.2){
          twistMsg = {linear: {x: 0.0, y: 0.0, z: 0.0}, angular: {x: 0.0, y: 0.0, z: -speeder/100}}
          gamepadDirection = 'LEFT'
        } else if(y > 0.2){
          twistMsg = {linear: {x: -speeder/100, y: 0.0, z: 0.0}, angular: {x: 0.0, y: 0.0, z: 0.0}}
          gamepadDirection = 'BACKWARD'
        } else if(y < -0.2){
          twistMsg = {linear: {x: speeder/100, y: 0.0, z: 0.0}, angular: {x: 0.0, y: 0.0, z: 0.0}}
          gamepadDirection = 'FORWARD'
        } else {
          twistMsg = {linear: {x: 0.0, y: 0.0, z: 0.0}, angular: {x: 0.0, y: 0.0, z: 0.0}}
          gamepadDirection = 'STOP'
        }

        if(gamepadDirection === lastGame) return;
        lastGame = gamepadDirection

        // fetch("/twist", {
        //   method: "POST",
        //   body: JSON.stringify({ robot: robot, twist: twistMsg }),
        //   headers: {
        //     'Content-Type': 'application/json'
        //   },
        // })
        // .then((res) => res.json())

        socket.emit("twist", { robot: robot, twist: twistMsg });

      }
    }, 100)

  }, [robot, socket])

  const joy = (stick) => {
    if(stick.direction === lastStick) return;
    // console.log(stick);
    var twistMsg;
    if(stick.type === 'stop'){
      twistMsg = {linear: {x: 0.0, y: 0.0, z: 0.0}, angular: {x: 0.0, y: 0.0, z: 0.0}}
    } else if (stick.direction === 'FORWARD') {
      twistMsg = {linear: {x: speed/100, y: 0.0, z: 0.0}, angular: {x: 0.0, y: 0.0, z: 0.0}}
    } else if (stick.direction === 'BACKWARD') {
      twistMsg = {linear: {x: -speed/100, y: 0.0, z: 0.0}, angular: {x: 0.0, y: 0.0, z: 0.0}}
    } else if (stick.direction === 'LEFT') {
      twistMsg = {linear: {x: 0.0, y: 0.0, z: 0.0}, angular: {x: 0.0, y: 0.0, z: speed/100}}
    } else if (stick.direction === 'RIGHT') {
      twistMsg = {linear: {x: 0.0, y: 0.0, z: 0.0}, angular: {x: 0.0, y: 0.0, z: -speed/100}}
    }
    setLastStick(stick.direction);
    // fetch("/twist", {
    //   method: "POST",
    //   body: JSON.stringify({ robot: robot, twist: twistMsg }),
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    // })
    // .then((res) => res.json())

    // // Send twist via sockets instead of http
    socket.emit("twist", { robot: robot, twist: twistMsg });

  }

  const handleKeyDown = (event) => {
    // console.log(event.key);
    // add WASD key control
  };

  return (
    <div className="App" onKeyDown={handleKeyDown} tabIndex={0}>
      <header className="App-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex">
                <img alt="robot" src={`https://robohash.org/${robot}?size=40x40`}/>
                {presence ?
                  <>
                  Online
                  {p2p ? " : peered" : " : peering"}
                  </>
                : "Offline"}
              </div>
              <div className="d-flex justify-content-center">
                {rgbStream ? <img src={rgbStream} width="424" height="240"/>: ''}
              </div>
              <br/>
              <div className="d-flex justify-content-center">
                <Joystick className="" size={100} sticky={false} baseColor="gray" stickColor="#bd93f9" move={joy} stop={joy}></Joystick>
              </div>
              <label htmlFor="customRange1" className="form-label">Speed</label>
              <input type="range" className="form-range" id="speeder" min="0" max="100" value={speed} step="5" onChange={e => setSpeed(e.target.value)}/>
              {robots.length === 0 ? "No robots created yet..." :
              <select onChange={e => setRobot(e.target.value)} className="form-select" id="mrRobot">
                {robots.map((robot, index) => (
                  <option key={index} value={robot.id}>{robot.name}</option>
                ))}
              </select>
              }
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Control;
