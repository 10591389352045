import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBtKEH15ui2vkRVJFj1FkWAxm5Ra48l-HA",
  authDomain: "robotics-develop.firebaseapp.com",
  projectId: "robotics-develop",
  storageBucket: "robotics-develop.appspot.com",
  messagingSenderId: "541985917575",
  appId: "1:541985917575:web:00bba63cd255fb1c7ac622",
  measurementId: "G-6RP36P3FWC"
};

const firebaseApp = initializeApp(firebaseConfig);

export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);

export {
    firebaseApp
}
