import React, { useEffect, useState }  from "react";
import { NavLink, useNavigate } from 'react-router-dom'

import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from '../firebase';
import { addDoc, getDoc, getDocs, doc, setDoc, deleteDoc, updateDoc, deleteField, query, collection, where } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
import "./../App.css";

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-WQXKE0M3J5";

function Automations() {
  const navigate = useNavigate();
  const [uid, setUid] = useState('');
  const [automations, setAutomations] = useState([]);

  async function getAutomations(userid){
    const q = query(collection(db, "automations"), where("uid", "==", userid));
    const querySnapshot = await getDocs(q);
    if(querySnapshot.size > 0){
      let automationList = [];
      querySnapshot.forEach((doc) => {
        var autoX = doc.data();
        autoX.id = doc.id;
        automationList.push(autoX);
      });
      setAutomations(automationList);
    } else {
      setAutomations([]);
    }
  }

  const deleteAutomation = async (auto) => {
    await deleteDoc(doc(db, "automations", auto));
    getAutomations(uid)
  }

  const editAutomation = async (autoId, name, xml) => {
    navigate('/designer', { state: {id: autoId, name: name, xml: xml} });
  }

  useEffect(() => {
      ReactGA.initialize(TRACKING_ID);
      ReactGA.send({ hitType: "pageview", page: "/automations", title: "Automations Page" });

      onAuthStateChanged(auth, (user) => {
          if (user) {
            setUid(user.uid);
            getAutomations(user.uid);
          } else {
            console.log("user is logged out")
            navigate("/")
          }
        });
  }, [])

  return (
    <div className="App">
      <header className="App-header">

        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-center">
                {automations.length === 0 ? "No automations created yet..." :
                <table className="table table-striped table-dark">
                <thead>
                  <tr key={Math.random()}>
                    <th scope="col" key={Math.random()}>Name</th>
                    <th scope="col" key={Math.random()}>Automation Id</th>
                    <th scope="col" key={Math.random()}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {automations.map((automation, index) => (
                    <tr key={index}>
                      <td key={Math.random()}>{automation.name}</td>
                      <td key={Math.random()}>{automation.id}</td>
                      <td key={Math.random()}>
                        <button className="btn btn-outline-light btn-lg" onClick={() => editAutomation(automation.id, automation.name, automation.xml)}>
                          Edit
                        </button>&nbsp;
                        <button className="btn btn-outline-danger btn-lg" onClick={() => deleteAutomation(automation.id)}>
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
                </table>
                }
              </div>
              <div className="d-flex justify-content-center">
                <button className="btn btn-outline-light btn-lg" onClick={() => navigate("/designer", { state: {id: "new"}})}>
                  New Automation
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Automations;
