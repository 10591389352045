import * as Blockly from 'blockly/core';
import {javascriptGenerator, Order} from 'blockly/javascript';

const camera = {
  init: function() {
    this.appendDummyInput('NAME')
      .appendField('get camera snapshot from robot')
      .appendField(new Blockly.FieldTextInput('robot id'), 'robotId');
    // this.setPreviousStatement(true);
    this.setOutput(true, 'String');
    // this.setNextStatement(true);
    this.setTooltip('');
    this.setHelpUrl('');
    this.setColour(225);
  }
};
Blockly.common.defineBlocks({camera: camera});

javascriptGenerator.forBlock['camera'] = function(block) {
  const text_robotid = block.getFieldValue('robotId');
  const code = `
    (function() {
      var request = new XMLHttpRequest()
      request.open("GET", "/photo/${text_robotid}", false)
      request.send()
      var value = JSON.parse(request.responseText)
      var valueJson = value.base64
      // console.log(valueJson)
      return valueJson;
    })()
  `
  // return code;
  return [code, Order.NONE]
}

const yolo = {
  init: function() {
    this.appendEndRowInput('yolox')
      .appendField('perform object detection on robot ')
      .appendField(new Blockly.FieldTextInput('robot id'), 'robotId');
    // this.setPreviousStatement(true, 'String');
    // this.setNextStatement(true, 'String');
    this.setOutput(true, 'String');
    this.setTooltip('');
    this.setHelpUrl('');
    this.setColour(225);
  }
};
Blockly.common.defineBlocks({yolo: yolo});


javascriptGenerator.forBlock['yolo'] = function(block) {
  const text_robotid = block.getFieldValue('robotId');
  const code = `
    (function() {
      var request = new XMLHttpRequest()
      request.open("GET", "/photo/${text_robotid}", false)
      request.send()
      var value = JSON.parse(request.responseText)
      var valueJson = value.base64
      detect_objects_on_image(valueJson)
      .then((boxes) => {
        console.log(boxes.toString());
        return boxes.toString()
      })
    })()
  `
  // return code;
  return [code, Order.NONE]
}

const stop = {
  init: function() {
    this.appendDummyInput('NAME')
      .appendField('stop')
      .appendField(new Blockly.FieldTextInput('robot id'), 'robotId');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setTooltip('');
    this.setHelpUrl('');
    this.setColour(225);
  }
};
Blockly.common.defineBlocks({stop: stop});

javascriptGenerator.forBlock['stop'] = function(block) {
  const text_robotid = block.getFieldValue('robotId');
  // const code = `socket.emit("twist", { robot: "${text_robotid}", twist: {linear: {x: 0.0, y: 0.0, z: 0.0}, angular: {x: 0.0, y: 0.0, z: 0.0}} });`
  const code = `
  fetch('/twist', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json' // Specify the content type
    },
    body: JSON.stringify({"robot": "${text_robotid}","twist": {"linear": {"x": 0.0, "y": 0.0, "z": 0.0}, "angular": {"x": 0.0, "y": 0.0, "z": 0.0}}})
  })
  .then(response => {
    // Handle the response
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json(); // Assuming the response is JSON
  })
  .then(data => {
    // Do something with the data
    console.log(data);
  })
  .catch(error => {
    // Handle any errors
    console.error('Error:', error);
  });
  `
  return code;
}

const forward = {
  init: function() {
    this.appendDummyInput('NAME')
      .appendField('move')
      .appendField(new Blockly.FieldTextInput('robot id'), 'robotId')
      .appendField('forward at speed of')
      .appendField(new Blockly.FieldTextInput('20'), 'speed')
      .appendField('% velocity');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setTooltip('');
    this.setHelpUrl('');
    this.setColour(225);
  }
};
Blockly.common.defineBlocks({forward: forward});

javascriptGenerator.forBlock['forward'] = function(block) {
  const text_robotid = block.getFieldValue('robotId');
  const text_speed = block.getFieldValue('speed');
  // const code = `socket.emit("twist", { robot: "${text_robotid}", twist: {linear: {x: ${text_speed/100}, y: 0.0, z: 0.0}, angular: {x: 0.0, y: 0.0, z: 0.0}} });`
  const code = `
  fetch('/twist', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json' // Specify the content type
    },
    body: JSON.stringify({"robot": "${text_robotid}","twist": {"linear": {"x": ${text_speed/100}, "y": 0.0, "z": 0.0}, "angular": {"x": 0.0, "y": 0.0, "z": 0.0}}})
  })
  .then(response => {
    // Handle the response
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json(); // Assuming the response is JSON
  })
  .then(data => {
    // Do something with the data
    console.log(data);
  })
  .catch(error => {
    // Handle any errors
    console.error('Error:', error);
  });
  `
  return code;
}

const turn = {
  init: function() {
    this.appendDummyInput('NAME')
      .appendField('turn')
      .appendField(new Blockly.FieldTextInput('robot id'), 'robotId')
      .appendField('at speed of')
      .appendField(new Blockly.FieldTextInput('20'), 'turn')
      .appendField('% velocity');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setTooltip('negative value is left, positive is right');
    this.setHelpUrl('');
    this.setColour(225);
  }
};
Blockly.common.defineBlocks({turn: turn});

javascriptGenerator.forBlock['turn'] = function(block) {
  const text_robotid = block.getFieldValue('robotId');
  const text_turn = block.getFieldValue('turn');
  // const code = `socket.emit("twist", { robot: "${text_robotid}", twist: {linear: {x: 0.0, y: 0.0, z: 0.0}, angular: {x: 0.0, y: 0.0, z: ${text_turn/100}}} });`
  const code = `
  fetch('/twist', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json' // Specify the content type
    },
    body: JSON.stringify({"robot": "${text_robotid}","twist": {"linear": {"x": 0.0, "y": 0.0, "z": 0.0}, "angular": {"x": 0.0, "y": 0.0, "z": ${text_turn/100}}}})
  })
  .then(response => {
    // Handle the response
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json(); // Assuming the response is JSON
  })
  .then(data => {
    // Do something with the data
    console.log(data);
  })
  .catch(error => {
    // Handle any errors
    console.error('Error:', error);
  });
  `
  return code;
}

const delay = {
  init: function() {
    this.appendDummyInput('delay')
      .appendField('delay for ')
      .appendField(new Blockly.FieldTextInput('1000'), 'delayTime')
      .appendField('milliseconds');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setTooltip('1 second = 1000 ms');
    this.setHelpUrl('');
    this.setColour(225);
  }
};
Blockly.common.defineBlocks({delay: delay});

javascriptGenerator.forBlock['delay'] = function(block) {
  const text_delaytime = block.getFieldValue('delayTime');
  const code = `
      sleep(${text_delaytime})
  `;
  return code;
}
