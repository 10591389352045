import React, { useEffect, useState }  from "react";
import { NavLink, useNavigate } from 'react-router-dom'

import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from '../firebase';
import { addDoc, getDoc, getDocs, doc, setDoc, deleteDoc, updateDoc, deleteField, query, collection, where } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
import "./../App.css";
import { CopyBlock, CodeBlock, dracula } from "react-code-blocks";

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-WQXKE0M3J5";

function Robots() {
  const navigate = useNavigate();
  const [uid, setUid] = useState('');
  const [robot, setRobot] = useState('');
  const [robots, setRobots] = useState([]);
  const [robotName, setRobotName] = useState('');
  const [addRobot, setAddRobot] = useState(false);
  const [newRobot, setNewRobot] = useState(false);
  const [postRos, setPostRos] = useState('ROS2');
  const [postCamera, setPostCamera] = useState('none');
  const [postType, setPostType] = useState('AMR');
  const [postWheels, setPostWheels] = useState(undefined);
  const [postWheelDiameter, setPostWheelDiameter] = useState(undefined);
  const [postWheelWidth, setPostWheelWidth] = useState(undefined);
  const [postWheelBetween, setPostWheelBetween] = useState(undefined);
  const [postRosTopics, setPostRosTopics] = useState([]);

  async function getRobots(userid){
    setAddRobot(false)
    const q = query(collection(db, "robots"), where("uid", "==", userid));
    const querySnapshot = await getDocs(q);
    if(querySnapshot.size > 0){
      let robotList = [];
      querySnapshot.forEach((doc) => {
        var robotX = doc.data();
        robotX.id = doc.id;
        robotList.push(robotX);
      });
      setRobots(robotList);
    } else {
      setRobots([]);
    }
  }

  const handleAddRobot = () => {
    setRobot('');
    setRobotName('')
    setPostType('AMR');
    setPostRos('ROS2');
    setPostCamera('none');
    setPostWheels(undefined);
    setPostWheelDiameter(undefined);
    setPostWheelWidth(undefined);
    setPostWheelBetween(undefined);
    setPostRosTopics([]);
    setAddRobot(!addRobot)
    setNewRobot(true)
  }

  const updateRobot = () => {
    var robotid
    if (robot === ""){
      robotid = uuidv4();
    } else {
      robotid = robot;
    }
    setDoc(doc(db, "robots", robotid), {
      uid: uid,
      name: robotName ? robotName : "",
      type: postType,
      ros: postRos,
      camera: postCamera,
      wheelCount: postWheels ? parseInt(postWheels) : 0,
      wheelDiameter: postWheelDiameter ? parseInt(postWheelDiameter) : 0,
      wheelWidth: postWheelWidth ? parseInt(postWheelWidth) : 0,
      wheelBetween: postWheelBetween ? parseInt(postWheelBetween) : 0,
      rosTopics: postRosTopics ? postRosTopics : []
    }, { merge: true })
    .then(
      getRobots(uid)
    )
  }

  const addRosTopic = async () => {
    // ROS_TOPICS=[{"type":"nav_msgs/msg/Odometry","topic":"/odom"}]
    var type = document.getElementById("msgType").value;
    var topic = document.getElementById("msgTopic").value;
    setPostRosTopics([...postRosTopics, {type: type, topic: topic }]);
  }
  const deleteRosTopic = async (topic) => {
    // ROS_TOPICS=[{"type":"nav_msgs/msg/Odometry","topic":"/odom"}]
    // setPostRosTopics([...postRosTopics, {type: type, topic: topic }]);
    // Create a new array without the item to be removed
    const updatedTopics = postRosTopics.filter(item => item.topic !== topic);
    setPostRosTopics(updatedTopics);
  }

  const deleteRobot = async (bot) => {
    // console.log(bot);
    await deleteDoc(doc(db, "robots", bot));
    getRobots(uid)
  }

  const handleRobotId = (bot) => {
    // console.log(bot.target.value);
    // setRobot(bot.target.value);
    for (var i = 0, l = robots.length; i < l; i++) {
      if(robots[i].id === bot){
        // console.log(robots[i]);
        setRobot(robots[i].id);
        setRobotName(robots[i].name)
        setPostType(robots[i].type);
        setPostRos(robots[i].ros);
        setPostCamera(robots[i].camera);
        setPostWheels(robots[i].wheelCount);
        setPostWheelDiameter(robots[i].wheelDiameter);
        setPostWheelWidth(robots[i].wheelWidth);
        setPostWheelBetween(robots[i].wheelBetween);
        if(robots[i].rosTopics){
            setPostRosTopics(robots[i].rosTopics);
        } else {
          setPostRosTopics([])
        }

      }
    }
    setAddRobot(!addRobot);
    setNewRobot(false);
  };

  useEffect(() => {
      ReactGA.initialize(TRACKING_ID);
      ReactGA.send({ hitType: "pageview", page: "/robots", title: "Robots Page" });

      onAuthStateChanged(auth, (user) => {
          if (user) {
            setUid(user.uid);
            getRobots(user.uid);
          } else {
            console.log("user is logged out")
            navigate("/")
          }
        });

  }, [])

  return (
    <div className="App">
      <header className="App-header">

        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-center">
                {robots.length === 0 ? "No robots created yet..." :
                <table className="table table-striped table-dark">
                <thead>
                  <tr key={Math.random()}>
                    <th scope="col" key={Math.random()}></th>
                    <th scope="col" key={Math.random()}>Name</th>
                    <th scope="col" key={Math.random()}>Robot Id</th>
                    <th scope="col" key={Math.random()}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {robots.map((robot, index) => (
                    <tr key={index}>
                      <td key={Math.random()}><img alt="robot" src={`https://robohash.org/${robot.id}?size=40x40`}/></td>
                      <td key={Math.random()}>{robot.name}</td>
                      <td key={Math.random()}>
                      <CopyBlock
                        text={`${robot.id}`}
                        language={"bash"}
                        showLineNumbers={false}
                        wrapLongLines
                        theme={dracula}
                      />
                      </td>
                      <td key={Math.random()}>
                        <button className="btn btn-outline-light btn-lg" onClick={() => handleRobotId(robot.id)}>
                          Edit
                        </button>&nbsp;
                        <button className="btn btn-outline-danger btn-lg" onClick={() => deleteRobot(robot.id)}>
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
                </table>
                }
              </div>
              <div className="d-flex justify-content-center">
                <button className="btn btn-outline-light btn-lg" onClick={handleAddRobot}>
                  Add Robot
                </button>
              </div>
              {addRobot ?
                <>
                <br/>
                <input className="form-control form-control-lg" type="text" placeholder="Robot Name" aria-label="robot name" onChange={e => setRobotName(e.target.value)} value={robotName}/>
                <input className="form-control form-control-lg" type="text" placeholder="Robot Id" aria-label="robot id" onChange={e => setRobot(e.target.value)} value={robot} disabled={!newRobot}/>

                <p>
                Type of Robot: &nbsp;
                <select id="type" onChange={e => setPostType(e.target.value)} value={postType}>
                   <option value="AMR">AMR</option>
                   <option value="AGV">AGV</option>
                   <option value="Stationary">Stationary</option>
                   <option value="Drone">Drone</option>
                </select>
                </p>
                <p>
                ROS Version: &nbsp;
                <select id="ros" onChange={e => setPostRos(e.target.value)} value={postRos}>
                   <option value="ROS2">ROS2</option>
                   <option value="ROS1">ROS1</option>
                </select>
                </p>
                <p>
                RealSense Camera: &nbsp;
                <select id="ros" onChange={e => setPostCamera(e.target.value)} value={postCamera}>
                   <option value="none">None</option>
                   <option value="D405">D405</option>
                   <option value="D415">D415</option>
                   <option value="D435">D435</option>
                   <option value="D435i">D435i</option>
                   <option value="D455">D455</option>
                   <option value="D456">D456</option>
                   <option value="D457">D457</option>
                </select>
                </p>
                <input className="form-control form-control-lg" type="text" placeholder="Wheel Count" onChange={e => setPostWheels(e.target.value)} value={postWheels}/>
                <input className="form-control form-control-lg" type="text" placeholder="Wheel Diameter (mm)" onChange={e => setPostWheelDiameter(e.target.value)} value={postWheelDiameter}/>
                <input className="form-control form-control-lg" type="text" placeholder="Wheel Width (mm)" onChange={e => setPostWheelWidth(e.target.value)} value={postWheelWidth}/>
                <input className="form-control form-control-lg" type="text" placeholder="Distance Between Wheels (mm)" onChange={e => setPostWheelBetween(e.target.value)} value={postWheelBetween}/>
                <p>ROS2 Topics:</p>
                <div className="row">
                  <div className="col-md-6">
                    <input className="form-control form-control-lg" type="text" placeholder="Message Type" id="msgType"/>
                  </div>
                  <div className="col-md-5">
                    <input className="form-control form-control-lg" type="text" placeholder="Message Topic" id="msgTopic"/>
                  </div>
                  <div className="col-md-1">
                    <button className="btn btn-outline-light btn-lg" onClick={() => addRosTopic()}>
                      Add
                    </button>
                  </div>
                </div>

                {postRosTopics ? postRosTopics.map((topic) => (
                  <div >{topic.type} : {topic.topic} &nbsp;
                  <button className="btn btn-outline-light btn-lg" onClick={() => deleteRosTopic(topic.topic)}>
                    Delete
                  </button>
                  </div>
                )) : ""}
                <br/>
                <button className="btn btn-outline-light btn-lg" onClick={updateRobot}>
                  Submit
                </button>
                <br/><br/>
                </>
              :
                ""
              }
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Robots;
